@if (isBrowser) {
  <p-toast
    life="5000"
    styleClass="toasts"
  />

  <ng-template
    #overlay
    let-isLoading
    [ngTemplateOutlet]="overlay"
    [ngTemplateOutletContext]="{
      $implicit: isLoading$ | async,
    }"
  >
    @if (isLoading) {
      <div class="loader-overlay">
        <div class="loader-spinner">
        </div>
      </div>
    }
  </ng-template>

  <router-outlet />
}
