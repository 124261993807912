import { Routes } from '@angular/router';

import {
  GlobalRoutes,
  ModuleRoutes,
} from './core/enums/module-routes.enum';
import { appResolver } from './core/resolvers/app.resolver';
import { AuthRoutes } from './modules/auth/enums/auth-routes.enum';

export const routes: Routes = [
  {
    pathMatch: 'full',
    path: GlobalRoutes.Empty,
    redirectTo: `/${ModuleRoutes.Auth}/${AuthRoutes.Login}`,
  },
  {
    path: 'auth/sso/callback',
    loadComponent: () => import('@App/modules/auth/components/sso/sso.component')
      .then((module) => module.SSOComponent),
  },
  {
    path: 'auth/sso/web/callback',
    loadComponent: () => import('@App/modules/auth/components/web-sso/web-sso.component')
      .then((module) => module.WebSSOComponent),
  },
  {
    path: GlobalRoutes.Empty,
    loadComponent: () => import('./core/components/template/template.component')
      .then((module) => module.TemplateComponent),
    children: [
      {
        path: ModuleRoutes.Auth,
        loadChildren: () => import('@App/modules/auth/auth.routes').then((auth) => auth.routes),
      },
    ],
  },
  {
    path: GlobalRoutes.Empty,
    resolve: {
      app: appResolver,
    },
    children: [
      {
        path: GlobalRoutes.Empty,
        loadComponent: () => import('./core/components/template/template.component')
          .then((module) => module.TemplateComponent),
        children: [
          {
            path: GlobalRoutes.Expired,
            loadComponent: () => import('./home/components/expired/expired.component')
              .then((module) => module.ExpiredComponent),
          },
          {
            path: GlobalRoutes.Home,
            loadComponent: () => import('./home/components/app-launcher/app-launcher.component')
              .then((module) => module.AppLauncherComponent),
          },
          {
            path: ModuleRoutes.Learner,
            loadChildren: () => import('@App/modules/learner/learner.routes')
              .then((learner) => learner.routes),
          },
          {
            path: ModuleRoutes.Facilitator,
            loadChildren: () => import('@App/modules/facilitator/facilitator.routes')
              .then((facilitator) => facilitator.routes),
          },
        ],
      },
      {
        path: ModuleRoutes.Admin,
        loadComponent: () => import('./modules/admin/components/template/template.component')
          .then((module) => module.TemplateComponent),
        loadChildren: () => import('@App/modules/admin/admin.routes')
          .then((admin) => admin.routes),
      },
    ],
  },
  {
    path: ModuleRoutes.Demo,
    loadChildren: () => import('@App/modules/demo/demo.routes').then((demo) => demo.routes),
  },
  {
    path: '**',
    redirectTo: GlobalRoutes.Home,
  },
];
