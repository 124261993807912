import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  CreateEffectMetadata,
  ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  map,
  Observable,
  switchMap,
  tap,
} from 'rxjs';

import { AuthRepository } from '../auth.repository';
import {
  LoginProps,
  OIDCProps,
  VerifyLoginProps,
} from '../types/auth-props.type';
import { Profile } from '../types/profile.type';

import { AuthActions } from './auth.actions';

@Injectable()
export class AuthEffects {
  public constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly authRepository: AuthRepository,
  ) {
  }

  public login$: CreateEffectMetadata = createEffect(() => (
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap(({ email }: LoginProps): Observable<void> => this.authRepository.login(email)),
      tap((): void => {
        this.store.dispatch(AuthActions.setPending({ pending: false }));
      }),
      catchError((error: HttpErrorResponse): never => {
        this.store.dispatch(AuthActions.setError({ error: true }));
        this.store.dispatch(AuthActions.setPending({ pending: false }));

        throw error;
      }),
    )
  ), { dispatch: false });

  public verifyLogin$: CreateEffectMetadata = createEffect(() => (
    this.actions$.pipe(
      ofType(AuthActions.verifyLogin),
      switchMap(({ otp, email }: VerifyLoginProps): Observable<void> => (
        this.authRepository.verifyLogin(email, otp)
      )),
      tap((): void => {
        this.store.dispatch(AuthActions.setPending({ pending: false }));
      }),
      catchError((error: HttpErrorResponse): never => {
        this.store.dispatch(AuthActions.setError({ error: true }));
        this.store.dispatch(AuthActions.setPending({ pending: false }));

        throw error;
      }),
    )
  ), { dispatch: false });

  public oidc$: CreateEffectMetadata = createEffect(() => (
    this.actions$.pipe(
      ofType(AuthActions.oidc),
      switchMap(({ idToken, code, redirectUri }: OIDCProps): Observable<void> => {
        if (idToken) {
          return this.authRepository.oidc(idToken);
        } else if (code && redirectUri) {
          return this.authRepository.oidcWithCode(code, redirectUri);
        } else {
          throw new Error('Either idToken or code and redirectUri are required');
        }
      }),
      tap((): void => {
        this.store.dispatch(AuthActions.setPending({ pending: false }));
      }),
      catchError((error: HttpErrorResponse): never => {
        this.store.dispatch(AuthActions.setError({ error: true }));
        this.store.dispatch(AuthActions.setPending({ pending: false }));

        throw error;
      }),
    )
  ), { dispatch: false });

  // public oidc$: CreateEffectMetadata = createEffect(() => (
  //   this.actions$.pipe(
  //     ofType(AuthActions.oidc),
  //     switchMap(({ idToken }: OIDCProps): Observable<void> => (
  //       this.authRepository.oidc(idToken)
  //     )),
  //     tap((): void => {
  //       this.store.dispatch(AuthActions.setPending({ pending: false }));
  //     }),
  //     catchError((error: HttpErrorResponse): never => {
  //       this.store.dispatch(AuthActions.setError({ error: true }));
  //       this.store.dispatch(AuthActions.setPending({ pending: false }));

  //       throw error;
  //     }),
  //   )
  // ), { dispatch: false });

  public getProfile$: CreateEffectMetadata = createEffect(() => (
    this.actions$.pipe(
      ofType(AuthActions.getProfile),
      switchMap((): Observable<Profile> => this.authRepository.getProfile()),
      tap((): void => {
        this.store.dispatch(AuthActions.setPending({ pending: false }));
      }),
      catchError((error: HttpErrorResponse): never => {
        this.store.dispatch(AuthActions.setError({ error: true }));
        this.store.dispatch(AuthActions.setPending({ pending: false }));

        throw error;
      }),
      map((profile: Profile) => AuthActions.getProfileSuccess({ profile })),
    )
  ));

  public logout$: CreateEffectMetadata = createEffect(() => (
    this.actions$.pipe(
      ofType(AuthActions.logout),
      switchMap((): Observable<void> => this.authRepository.logout()),
      tap((): void => {
        this.store.dispatch(AuthActions.setPending({ pending: false }));
      }),
      catchError((error: HttpErrorResponse): never => {
        this.store.dispatch(AuthActions.setError({ error: true }));
        this.store.dispatch(AuthActions.setPending({ pending: false }));

        throw error;
      }),
    )
  ), { dispatch: false });
}
