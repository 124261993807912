import { isPlatformBrowser } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {
  inject,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import {
  catchError,
  finalize,
  Observable,
  of,
} from 'rxjs';

import { AuthRoutes } from '@App/modules/auth/enums/auth-routes.enum';

import {
  GlobalRoutes,
  ModuleRoutes,
} from '../enums/module-routes.enum';
import { Severities } from '../enums/severities.enum';
import { LoaderService } from '../services/loader.service';

export const httpInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const isBrowser: boolean = isPlatformBrowser(inject(PLATFORM_ID));

  if (isBrowser) {
    const skip: boolean = !!request.headers.get('skip');
    const newRequest: HttpRequest<unknown> = request.clone({
      withCredentials: true,
      headers: request.headers.delete('skip').set('ngsw-bypass', 'true'),
    });

    if (skip) {
      return next(newRequest);
    }

    const router: Router = inject(Router);
    const messageService: MessageService = inject(MessageService);
    const translateService: TranslateService = inject(TranslateService);
    const loaderService: LoaderService = inject(LoaderService);

    if (!request?.url?.includes('chat-message-read-status')) {
      loaderService.showLoader();
    }

    return next(newRequest)
      .pipe(
        finalize(() => {
          loaderService.hideLoader();
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 || error.status === 403) {
            router.navigate([
              GlobalRoutes.Root,
              ModuleRoutes.Auth,
              AuthRoutes.Login,
            ]);
          }

          if (error.error.message) {
            let displayMessage = error.error.message;
            let detailMessage: string;

            if (displayMessage?.includes('Validation failed. ')) {
              displayMessage = displayMessage.replace('Validation failed. ', '');
              detailMessage = displayMessage;
            } else if (displayMessage?.includes('Internal server error') ||
              displayMessage?.includes('PROGRAM_IMPORT_ERROR:')) {
              detailMessage = displayMessage;
            } else {
              detailMessage = translateService.instant(`ERRORS.${displayMessage}`);
            }

            messageService.add({
              severity: Severities.Error,
              summary: translateService.instant('SEVERITIES.ERROR'),
              detail: detailMessage,
            });
          }

          throw error;
        }),
      );
  }

  return of(new HttpResponse());
};
