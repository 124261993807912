import {
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import {
  provideClientHydration,
  withEventReplay,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { provideUserIdleConfig } from 'angular-user-idle';
import { provideLottieOptions } from 'ngx-lottie';
import { MessageService } from 'primeng/api';

import { environment } from '@Environment';

import { routes } from './app.routes';
import {
  initialState,
  reducers,
} from './app.store';
import { httpInterceptor } from './core/interceptors/http.interceptor';
import { DEFAULT_LOCALE_PROVIDER } from './core/providers/default-locale.provider';
import { SENTRY_PROVIDERS } from './core/providers/sentry.provider';
import { TRANSLATE_LOADER_PROVIDER } from './core/providers/translate-loader.provider';
import { SystemRepository } from './core/repositories/system.repository';
import { SystemEffects } from './core/store/system/system.effects';
import { SystemFacade } from './core/store/system/system.facade';
import { AuthRepository } from './modules/auth/auth.repository';
import { AuthEffects } from './modules/auth/store/auth.effects';
import { AuthFacade } from './modules/auth/store/auth.facade';

export const appConfig: ApplicationConfig = {
  providers: [
    DEFAULT_LOCALE_PROVIDER,
    AuthFacade,
    SystemFacade,
    MessageService,
    AuthRepository,
    SystemRepository,
    SENTRY_PROVIDERS,
    provideAnimations(),
    provideRouter(routes),
    provideStore(reducers, { initialState }),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideClientHydration(
      withEventReplay(),
      withHttpTransferCacheOptions({
        includePostRequests: true,
        includeRequestsWithAuthHeaders: true,
      }),
    ),
    provideStoreDevtools({
      maxAge: 25,
      connectInZone: true,
      logOnly: !isDevMode(),
    }),
    provideEffects([
      AuthEffects,
      SystemEffects,
    ]),
    provideHttpClient(
      withInterceptors([
        httpInterceptor,
      ]),
    ),
    provideUserIdleConfig({
      timeout: 0,
      idle: environment.idleTimeout,
    }),
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    importProvidersFrom(
      TranslateModule.forRoot({ loader: TRANSLATE_LOADER_PROVIDER }),
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.prod || environment.uat || environment.stg,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
