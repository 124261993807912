import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import {
  browserTracingIntegration,
  init,
  replayIntegration,
} from '@sentry/angular';

import { environment } from '@Environment';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

if (environment.prod) {
  enableProdMode();
}

let currentEnvironment: string;

if (environment.prod) {
  currentEnvironment = 'production';
} else if (environment.uat) {
  currentEnvironment = 'uat';
} else {
  currentEnvironment = 'development';
}

const initializeSentry = environment.prod || environment.uat || environment.stg;

init({
  tracesSampleRate: 1.0,
  dsn: environment.sentryDsn,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: initializeSentry,
  environment: currentEnvironment,
  integrations: [
    replayIntegration(),
    browserTracingIntegration(),
  ],
  tracePropagationTargets: [
    'localhost',
    'https://pwc-neom-fe-stg.ideatolife.me',
    'https://pwc-neom-uat-fe.ideatolife.me',
    'https://pwc-neom-prod-fe.ideatolife.me',
  ],
});

console.log(`Sentry initialized in ${currentEnvironment} mode: ${initializeSentry}`);

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
