import {
  AsyncPipe,
  isPlatformBrowser,
  NgTemplateOutlet,
} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  Router,
  RouterOutlet,
} from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { UserIdleService } from 'angular-user-idle';
import { ToastModule } from 'primeng/toast';
import {
  concat,
  interval,
  switchMap,
  take,
} from 'rxjs';

import { GlobalRoutes } from './core/enums/module-routes.enum';
import { LoaderService } from './core/services/loader.service';
import { AuthFacade } from './modules/auth/store/auth.facade';
import { Profile } from './modules/auth/types/profile.type';

@Component({
  standalone: true,
  selector: 'll-root',
  styleUrl: './app.component.scss',
  templateUrl: './app.component.html',
  imports: [
    RouterOutlet,
    AsyncPipe,
    NgTemplateOutlet,
    ToastModule,
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  protected isBrowser!: boolean;
  public title: string = 'pwc-neom-frontend';

  private readonly destroy: DestroyRef = inject(DestroyRef);
  protected readonly isLoading$ = this.loaderService.isLoading$;

  public constructor(
    private readonly router: Router,
    private readonly swUpdate: SwUpdate,
    private readonly authFacade: AuthFacade,
    private readonly loaderService: LoaderService,
    private readonly userIdleService: UserIdleService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) protected readonly platformId: Record<string, unknown>,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.swUpdate?.isEnabled) {
      this.swUpdate?.unrecoverable?.subscribe((event) => {
        console.log(event);
        console.log('An error occurred that we cannot recover from:\n' +
          event?.reason +
          '\n\nPlease reload the page.');
        this.clearCacheAndReload();
      });

      const everyTwoMinutes$ = interval(2 * 60 * 1000);
      const everyTwoMinutesOnceAppIsStable$ = concat(everyTwoMinutes$);
      everyTwoMinutesOnceAppIsStable$?.subscribe(async () => {
        try {
          const updateFound = await this.swUpdate?.checkForUpdate();
          if (updateFound) {
            console.log('New version is available.');
            this.swUpdate?.activateUpdate()?.then(() => {
              this.clearCacheAndReload();
            });
          }
        } catch (err) {
          console.error('Failed to check for updates:', err);
        }
      });

      this.swUpdate?.versionUpdates?.subscribe((evt) => {
        switch (evt?.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${evt.version.hash}`);
            break;
          case 'VERSION_READY':
            console.log(`Current app version: ${evt.currentVersion.hash}`);
            console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
            this.swUpdate?.activateUpdate()?.then(() => {
              this.clearCacheAndReload();
            });
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
            break;
        }
      });
    }
  }

  public ngOnInit(): void {
    this.userIdleService.onTimerStart()
      .pipe(
        switchMap(() => this.authFacade.selectProfile$),
        take(1),
        takeUntilDestroyed(this.destroy),
      )
      .subscribe((profile: Profile | null) => {
        this.userIdleService.stopWatching();

        localStorage.setItem('email', profile?.email as string);

        this.router.navigate([
          GlobalRoutes.Root,
          GlobalRoutes.Expired,
        ]);
      });
  }

  public ngAfterViewInit(): void {
    this.isLoading$.subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  private clearCacheAndReload(): void {
    if (navigator?.serviceWorker) {
      navigator?.serviceWorker?.getRegistration()?.then((registration) => {
        if (registration) {
          // Unregister the service worker
          registration?.unregister()?.then(() => {
            console.log('Service worker unregistered.');

            // Clear all caches
            caches?.keys()?.then((cacheNames) => {
              return Promise.all(
                cacheNames?.map((cacheName) => {
                  console.log(`Clearing cache: ${cacheName}`);
                  return caches?.delete(cacheName);
                }),
              );
            }).then(() => {
              console.log('All caches cleared. Reloading the page...');
              window.location.reload(); // Reload the page
            }).catch((err) => {
              console.error('Error clearing caches:', err);
              window.location.reload(); // Reload even if cache clearing fails
            });
          });
        } else {
          console.log('No service worker found. Reloading the page...');
          window.location.reload();
        }
      });
    } else {
      console.error('Service worker is not supported in this browser.');
      window.location.reload();
    }
  }
}
